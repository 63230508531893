import React from "react";
import "./quote.css";
import { Parallax } from "react-parallax";
import BuildingImg from "../../assets/quote.avif";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Quote = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation only occurs once when in view
  });
  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 0 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 0 }}
        transition={{ duration: 1 }}
        className="quote"
      >
        <div className="quote-container">
          <Parallax strength={600} bgImage={BuildingImg}>
            <div className="quote-content">
              <div className="quote-text">
                <span>
                  <h1>"Aron is committed to building a bridge for tomorrow,</h1>
                </span>
                <span>
                  <h1>one innovative idea at a time,</h1>
                </span>
                <span>
                  <h1>
                    ensuring a strong foundation for the generations of dreams
                    to come."
                  </h1>
                </span>
                <h3>Sandip Regmi, MD</h3>
              </div>
            </div>
          </Parallax>
        </div>
      </motion.div>
    </>
  );
};

export default Quote;
