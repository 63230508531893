import React from "react";
import "./portfoliopage.css";
import BackgroundImg from "../../assets/pupi.jpg";
import { Data } from "./PortfolioData";
// import SliderOne from "../portfolioslider/SliderOne";
import GoToTop from "../GoToTop";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const PortfolioPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <>
      <div className="portfolio-page">
        <div
          className="services-container"
          style={{
            backgroundImage: `url(${BackgroundImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center 550px",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="services-box">
            <div>
              <p>{/* Home / <span>services</span> */}</p>
              <h1>PORTFOLIO</h1>
            </div>
          </div>
        </div>
        <div className="portfolio-content">
          {Data.map((items, index) => {
            return (
              <>
                <motion.div
                  ref={ref}
                  initial="hidden"
                  animate={inView ? controls : "hidden"}
                  //variants={variants}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="portfolio-content-card"
                  key={index}
                >
                  <div className="portfolio-content-card-img">
                    {items.slider}
                  </div>
                </motion.div>
              </>
            );
          })}
        </div>
      </div>
      <GoToTop />
    </>
  );
};

export default PortfolioPage;
