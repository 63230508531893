import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import logo1 from "../../assets/aron-logo.png";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [dropdownDestination, setDropdownDestination] = useState(false);
  const [dropdownAbout, setDropdownAbout] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const offset = currentScrollPos;

    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos, visible]);

  return (
    <nav
      className={`container navbar ${scrolled ? "dark-nav" : ""} ${
        visible ? "visible" : "hidden"
      }`}
    >
      <Link to="/" className="navbar-left">
        <img src={logo1} alt="LOGO" />
        <p>ARON ENGINEERING PVT.LTD.</p>
      </Link>
      <div className="navbar-right">
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className={`toggle ${showMenu ? "active" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className={`nav-links ${showMenu ? "active" : ""}`}>
          <li>
            <NavLink exact to="/" onClick={toggleMenu}>
              <span className="navbar-name"> HOME</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/portfolio" onClick={toggleMenu}>
              <span className="navbar-name"> PORTFOLIO</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/services" onClick={toggleMenu}>
              <span className="navbar-name"> SERVICES</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/teameverest" onClick={toggleMenu}>
              <span className="navbar-name"> MEET US</span>
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/life" onClick={toggleMenu}>
              <span className="navbar-name"> LIFE AT ARON</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/announcements" onClick={toggleMenu}>
              <span className="navbar-name"> ANNOUNCEMENTS</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={toggleMenu}>
              <span className="navbar-name"> CONTACT US</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
