import React from "react";
import "./about.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutimg from "../../assets/worldmap2.png";

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: -500 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
        transition={{ duration: 1 }}
        className="about"
      >
        <div className="about-content">
          <div className="about-content-text">
            <p>
              Aron Engineering is a Nepal based engineering design firm which
              mainly caters to structural design of components, drafting,
              modelling and rendering of structures. Regardless of complexity or
              scale, our dynamic team's professional standards, technical
              excellence, safe, innovative and sustainable engineering solutions
              are crafted to empower your challenging projects.
            </p>

            <div className="about-img-cont">
              <img className="about-img" src={aboutimg} />
            </div>

            <p>
              Our collaboration with DEKAT Engineering LLC offers an integrated
              technical team comprised of highly competent engineers and
              architects from both Nepal and the United States ensuring the
              highest-quality, cost-effective services on time which is our
              primary objective.
            </p>
          </div>
          <div className="about-btn">
            <a
              href="https://dekatengineering.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className="cta-aboutsec">
                <span>Learn more about DEKAT</span>
                <svg width="15px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </button>
            </a>
          </div>
        </div>
        <div className="about-data">
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Fast Turnaround ⌛</h1>
              <p>
                Our fast turnaround time isn't just a promise; it's a testament
                to our efficiency and dedication.
              </p>
            </div>
          </div>

          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Licensed Professionals 📄</h1>
              <p>
                Experts with hands-on experience familiar with world-wide design
                codes
              </p>
            </div>
          </div>
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Efficient Integration 💸</h1>
              <p>
                Experience seamless efficiency with our integrated and
                cost-effective services, where efficiency meets affordability to
                elevate design needs.
              </p>
            </div>
          </div>
          <div className="data">
            <div className="side-rectangle"></div>
            <div className="data-content">
              <h1>Knowledge hub 💡</h1>
              <p>
                Internships and seminar opportunities to facilitate knowledge
                exchange from team experts
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default About;
