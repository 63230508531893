import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./services.css";
import { Data } from "./Data";

const Services = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className="services"
    >
      <div className="services-content">
        <div className="section-title">
          {/* <h1 className="background-title">Engineering Services</h1> */}
          <h2 className="foreground-title">Engineering Services</h2>
        </div>
        {/* <div className="services-title">
          <h1>Construction and Structural Engineering Services</h1>
        </div> */}
        <div className="services-cta">
          <Link to="/services">
            <button className="cta">
              <span>More Services</span>
              <svg width="15px" height="10px" viewBox="0 0 13 10">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </Link>
        </div>
      </div>

      <div className="card2">
        {Data.map((item, index) => (
          <Card item={item} index={index} key={index} />
        ))}
      </div>
    </motion.div>
  );
};

const Card = ({ item, index }) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <motion.div
      ref={ref}
      className={`hp-servicecard ${inView ? "is-visible" : ""}`}
      style={{
        transitionDelay: `${index * 0.3}s`,
      }}
    >
      <div className="hp-serviceimage">
        <img src={item.img} alt={item.title} />
      </div>
      <div className={`hp-servicecontent ${item.color}`}>
        <svg
          className="hp-wave"
          viewBox={item.viewBox}
          preserveAspectRatio="none"
        >
          <path d={item.svgPath} fill={item.fillColor} />
        </svg>
        <h3 className="hp-servicetitle">{item.title}</h3>
        <p className="hp-servicedescription">{item.description}</p>
      </div>
    </motion.div>
  );
};

export default Services;
