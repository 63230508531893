import React, { useState } from "react";
import FullScreenCarousel from "./herosection/HeroSection";
import Services from "./services/Services";
// import Projects from "./project/Projects";
import About from "./about/About";
import Quote from "./quote/Quote";
// import AronServices from "./aronservices/AronServices";
import "./Home.css";
import popupImage from "../assets/Aron Competition Website.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className.includes("popup-overlay")) {
      closePopup();
    }
  };

  return (
    <div className="container">
      {showPopup && (
        <div className="popup-overlay" onClick={handleOverlayClick}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <img src={popupImage} alt="popup" />
            <div className="close-button" onClick={closePopup}>
              <FontAwesomeIcon icon={faCircleXmark} size="2xl" bounce />
            </div>
          </div>
        </div>
      )}
      <FullScreenCarousel />
      <About />
      <Quote />
      <Services />
      {/* <AronServices /> */}
      {/* <Projects /> */}
    </div>
  );
};

export default Home;
