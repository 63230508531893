import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackgroundImg from "../../../assets/pupi.jpg";
import "./JobDetailsPage.css";

const JobDetailsPage = () => {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_position: "",
    user_cover: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/jobs/${jobId}`
        );
        if (response.ok) {
          const data = await response.json();
          setJobDetails(data);
        } else {
          toast.error("Failed to fetch job details.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching job details.");
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "cv") {
      setSelectedFile(files[0]);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile || selectedFile.type !== "application/pdf") {
      toast.error("Please upload a valid PDF file.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("user_name", formData.user_name);
      formDataToSend.append("user_email", formData.user_email);
      formDataToSend.append("user_position", formData.user_position);
      formDataToSend.append("user_cover", formData.user_cover);
      formDataToSend.append("cv", selectedFile); // Ensure the 'cv' matches the backend expected name

      const response = await fetch(
        "https://backend.aron-engineering.com/api/submit-application",
        {
          method: "POST",
          body: formDataToSend,
          mode: "cors",
        }
      );

      if (response.ok) {
        toast.success("Application submitted successfully!");
      } else {
        const errorMessage = await response.text();
        toast.error(
          `Failed to submit application. Server response: ${errorMessage}`
        );
      }
    } catch (error) {
      toast.error("An error occurred while submitting the application.");
    }

    setSelectedFile(null);
    setFormData({
      user_name: "",
      user_email: "",
      user_position: "",
      user_cover: "",
    });
  };

  const renderTextWithNewLines = (text) => {
    if (!text) return null;

    return text.split(".").map((sentence, index) => (
      <React.Fragment key={index}>
        {sentence.trim()}
        {sentence && "."}
        <br />
      </React.Fragment>
    ));
  };

  if (!jobDetails) {
    return (
      <div className="loader">
        <label>Please wait...</label>
        <div className="loading"></div>
      </div>
    );
  }

  return (
    <>
      <div
        className="services-container"
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center 550px",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="services-box">
          <div>
            <p>{/* Home / <span>services</span> */}</p>
            <h1>OPENINGS</h1>
          </div>
        </div>
      </div>
      <div className="job-details-container">
        <div className="job-details-box">
          <div>
            <h1 className="job-title">{jobDetails.title}</h1>
            <div className="job-details-content">
              <strong>Description:</strong>
              <p>{jobDetails.description}</p>
              <strong>Duties:</strong>
              <p>{renderTextWithNewLines(jobDetails.duties)}</p>
              <strong>Qualifications:</strong>
              <p>{renderTextWithNewLines(jobDetails.qualifications)}</p>
              <strong>Preferred Skills:</strong>
              <p>{renderTextWithNewLines(jobDetails.preferredSkills)}</p>
              <strong>Work Schedule:</strong>
              <p>{jobDetails.workSchedule}</p>
              <strong>Location:</strong>
              <p>{jobDetails.location}</p>
              <strong>Compensation and Benefits:</strong>
              <p>{jobDetails.compensationAndBenefits}</p>
              <p>
                <strong>Expected Salary:</strong> ${jobDetails.expectedSalary}
              </p>
            </div>
          </div>
        </div>

        <div className="application-form" ref={formRef}>
          <h2 className="form-title">Apply for this Job</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="user_name"
              placeholder="Your Name"
              value={formData.user_name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="user_email"
              placeholder="Your Email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="user_position"
              placeholder="Position"
              value={formData.user_position}
              onChange={handleChange}
              required
            />
            <textarea
              name="user_cover"
              placeholder="Cover Letter"
              value={formData.user_cover}
              onChange={handleChange}
              required
            ></textarea>
            <input
              type="file"
              name="cv"
              accept=".pdf"
              onChange={handleChange}
              required
            />
            <button className="submit-button" type="submit">
              Submit Application
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default JobDetailsPage;
